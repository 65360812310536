<template>
    <div>
        <create-data-feed
            v-if="checkFeedEdit"
            :dropDownUniversesList="dropDownUniversesList"
            :dropDownTemplateList="dropDownTemplateList"
            :universeDynamicShowData="universeDynamicShowData"
            :universeStaticShowData="universeStaticShowData"
            :templateShowdata="templateShowdata"
            :dataFeedHeaderTitle="dataFeedHeaderTitle"
            :checkUniverseType="checkUniverseType"
            :dataFeedInputField="dataFeedInputField"
            :updateDataFeedData="updateDataFeedData"
            @editUniverseData="editUniverseData"
            @editTemplateData="editTemplateData"
            @saveDataFeed="saveDataFeed"
        >
        </create-data-feed>
        <loader-component v-if="showLoader"></loader-component>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>
    </div>
</template>

<script>
import get from "lodash/get";
import { mapGetters, mapActions, mapState } from "vuex";
import store from "@/store";
import CreateDataFeed from "../components/CreateDataFeed.vue";
import { DATADISSEMINATION_ACTIONS } from "@/store/modules/dataDissemination/contants";
import { getEditTemplateList } from "../../../../services/reporting_service.js";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import NotificationComponent from "../../../ui_component/notificationComponent.vue";

export default {
    beforeCreate() {
        this.$store = store;
    },
    components: {
        CreateDataFeed,
        LoaderComponent,
        NotificationComponent,
    },
    data() {
        return {
            universeDynamicShowData: [],
            universeStaticShowData: [],
            templateShowdata: [],
            checkUniverseType: "",
            showLoader: true,
            dataFeedHeaderTitle: "Create Feed",
            updateDataFeedData: {},
            showNotification: false,
            notificationMessage: "",
            notificationType: "",
            dataFeedInputField: false,
            feedid: 0
        };
    },
    async created() {
        try {
            await this.$store.dispatch(
                DATADISSEMINATION_ACTIONS.FETCH_DROPDOWN_UNIVERSE_LIST
            );
            await this.$store.dispatch(
                DATADISSEMINATION_ACTIONS.FETCH_DROPDOWN_TEMPLATE_LIST
            );
            this.showLoader = false;
            this.dropDownUniversesList;
            this.dropDownTemplateList;
        } catch (e) {
            this.showLoader = false;
        }
    },
    computed: {
        ...mapGetters(["cachedDispatch"]),
        ...mapGetters("reporting", ["getEditUniverseList"]),
        ...mapState({
            dropDownUniversesList(state) {
                return get(
                    state,
                    "dataDissemination.dropDownUniverses.dropDownUniverses",
                    []
                );
            },
            dropDownTemplateList(state) {
                return get(
                    state,
                    "dataDissemination.dropDownTemplates.dropDownTemplates",
                    []
                );
            },
            responseSaveDataFeed(state) {
                return get(
                    state,
                    "dataDissemination.saveDataFeeds.saveDataFeeds",
                    []
                );
            },
            responseUpdateDataFeed(state) {
                return get(
                    state,
                    "dataDissemination.updateDataFeeds.updateDataFeeds",
                    []
                );
            },
            getUpdateDataFeedList(state) {
                return get(
                    state,
                    "dataDissemination.getUpdateDataFeeds.getUpdateDataFeeds",
                    []
                );
            },
            getUpdateDataFeedListError(state) {
                return get(
                    state,
                    "dataDissemination.getUpdateDataFeeds.__error__",
                    null
                );
            },
            dataFeedSaveError(state) {
                return get(
                    state,
                    "dataDissemination.saveDataFeeds.__error__",
                    null
                );
            },
            dataFeedUpdateError(state) {
                return get(
                    state,
                    "dataDissemination.updateDataFeeds.__error__",
                    null
                );
            },
        }),
        checkFeedEdit() {
            if (this.$route.query.feedid) {
                return Object.keys(this.updateDataFeedData).length;
            } else {
                return true;
            }
        }
    },
    async mounted() {
        if (this.$route.query.feedid != undefined) {
            this.dataFeedInputField = true;
            let feedId = this.$route.query.feedid;
            await this.$store.dispatch(
                DATADISSEMINATION_ACTIONS.GET_UPDATE_DATAFEED_LIST,
                { feedId }
            );
            let response = this.getUpdateDataFeedList;
            const obj = { 
                response,
                ntfnfailureMsg: this.getUpdateDataFeedListError
            }
            if (this.getUpdateDataFeedListError) {
                this.notificationResponse(obj);
                return;
            }
            this.dataFeedHeaderTitle = "Edit Feed: " + response.feedname;
            const dataSetPoints = JSON.parse(response.templatefilter);
            const dataSets =
                (dataSetPoints.data &&
                    dataSetPoints.data.reduce((obj, item) => {
                        const newArrs = {
                            name: item.header_name,
                        };
                        obj.push(newArrs);
                        return obj;
                    }, [])) ||
                [];
            this.templateShowdata = dataSets;
            if (response.listfilter.filters == null || response.listfilter.filters.length == 0) {
                this.checkUniverseType = "Static";
                this.universeStaticShowData = response.listfilter.definitions;
            } else {
                this.checkUniverseType = "Dynamic";
                this.universeDynamicShowData = response.listfilter.filters;         
            }
            this.updateDataFeedData = response;
        }
    },
    methods: {
        ...mapActions("reporting", ["fetchEditUniverseList"]),

        async editUniverseData(val) {
            this.showLoader = true;
            // const selectedVal = JSON.parse(val);
            // let obj = {
            //     listid: selectedVal.id,
            //     isPublic: selectedVal.ispublic ? selectedVal.ispublic : false
            // };
            let obj = {
                listid: val,
                isPublic: false
            };
            await this.fetchEditUniverseList(obj);
            let response = this.getEditUniverseList.data;
            if (response.result.filters == null || response.result.filters.length == 0) {
                this.checkUniverseType = "Static";
                this.universeStaticShowData = response.result.definitions;
            } else {
                this.checkUniverseType = "Dynamic";
                this.universeDynamicShowData = response.result.filters;               
            }
            this.showLoader = false;
        },

        async editTemplateData(val) {
            this.showLoader = true;
            let response = await getEditTemplateList(val);
            const dataSetPoint = JSON.parse(response.data.filter);
            const dataSet =
                (dataSetPoint.data &&
                    dataSetPoint.data.reduce((obj, item) => {
                        const newArr = {
                            name: item.header_name,
                        };
                        obj.push(newArr);
                        return obj;
                    }, [])) ||
                [];
            this.templateShowdata = dataSet;
            this.showLoader = false;
        },

        async saveDataFeed(data) {
            if (this.$route.query.feedid == undefined) {
                await this.$store.dispatch(
                    DATADISSEMINATION_ACTIONS.SAVE_DATAFEED_LIST,
                    {
                        data,
                    }
                );
                let response = this.responseSaveDataFeed;
                const obj = {
                    response,
                    ntfnMsg: "The data feed has been created.",
                    ntfnType: "success",
                    ntfnKey: "success-default",
                    ntfnfailureMsg: this.dataFeedSaveError,
                };
                this.notificationResponse(obj);
                if (!this.dataFeedSaveError) {
                    setTimeout(() => {
                        this.$router.push({
                            name: "Data Dissemination Data Feed",
                        });
                    }, 2000);
                }
            } else {
                await this.$store.dispatch(
                    DATADISSEMINATION_ACTIONS.UPDATE_DATAFEED_LIST,
                    {
                        data,
                    }
                );
                let response = this.responseUpdateDataFeed;
                const obj = {
                    response,
                    ntfnMsg: "Your data feed has been updated.",
                    ntfnType: "success",
                    ntfnKey: "success-default",
                    ntfnfailureMsg: this.dataFeedUpdateError,
                };
                this.notificationResponse(obj);
                if (!this.dataFeedUpdateError) {
                    setTimeout(() => {
                        this.$router.push({
                            name: "Data Dissemination Data Feed",
                        });
                    }, 2000);
                }
            }
        },

        notificationResponse({
            response,
            ntfnMsg,
            ntfnType,
            ntfnKey,
            ntfnfailureMsg,
        }) {
            if (response.statuscode == 201 || response.statuscode == 200) {
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.showLoader = false;
            } else {
                this.showLoader = false;
                this.notificationKey = "error-default";
                this.notificationMessage = ntfnfailureMsg || response.data.message;
                this.notificationType = "error";
                this.showNotification = true;
            }
        },
    },
};
</script>

