<template>
    <div id="createDataFeed">
        <div class="create-data-feed">
            <div class="upperSection">
            <mds-layout-grid class="create-data-feed">
                <mds-row>
                    <span>
                        <router-link
                            :to="{
                                name: getRouteDetails(
                                    '/data-dissemination/data-feeds'
                                ),
                            }"
                        >
                            Data Feeds
                        </router-link>
                    </span>
                    <span class="create-data-feed-router">
                        / {{ dataFeedHeaderTitle }}
                    </span>
                </mds-row>
                <mds-row class="create-data-feed-heading">
                    {{ dataFeedHeaderTitle }}
                </mds-row>
            </mds-layout-grid>

            <mds-layout-grid>
                <mds-row class="create-data-feed-name-section">
                    <mds-col :cols="4" class="create-data-feed-name">
                        <mds-form>
                            <mds-input
                                maxlength="250"
                                label="Name"
                                placeholder="Enter a descriptive name"
                                v-model.trim="nameFeed"
                                :disabled="dataFeedInputField"
                            ></mds-input>
                        </mds-form>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>

            <mds-layout-grid>
                <mds-row
                    class="create-data-feed-selection-section"
                    style="margin-top: 16px"
                >
                    <!-- Universe Dropdown -->
                    <mds-col :cols="4" class="create-data-feed-type">
                        <mds-form>
                            <mds-select
                                label="Universe"
                                v-model="universe"
                                @change="changeUniverse"
                                id="setUniverse"
                                placeholder="Select a universe"
                            >
                                <option
                                    v-for="(item, index) in dropDownUniversesList"
                                    :key="index"
                                    :value="item.id"
                                    :selected="selectedUniverseId === parseInt(item.id)"
                                >
                                    {{ item.name }}
                                </option>
                            </mds-select>
                        </mds-form>
                    </mds-col>

                    <mds-col :col="2" style="margin-top: 30px">
                        <button-component
                            :onClick="universeButton"
                            buttonName="New Universe"
                            buttonVariation="flat"
                            iconName="plus"
                        >
                        </button-component>
                    </mds-col>

                    <!-- Template Dropdown -->
                    <mds-col :cols="4" class="create-data-feed-inputfield">
                        <mds-form>
                            <mds-select
                                label="Template"
                                :disabled="isVelotemplatetype === 2"
                                v-model="template"
                                @change="changeTemplate"
                                placeholder="Select a template"
                            >
                                <option
                                    v-for="(item, index) in dropDownTemplateList"
                                    :key="index"
                                    :value="item.id"
                                    :selected="selectedTemplateId === parseInt(item.id)"
                                >
                                    {{ item.name }}
                                </option>
                            </mds-select>
                        </mds-form>
                    </mds-col>

                    <mds-col :col="2" style="margin-top: 30px">
                        <button-component
                            :onClick="templateButton"
                            buttonName="New Template"
                            buttonVariation="flat"
                            iconName="plus"
                        >
                        </button-component>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>

            <div>
                <mds-layout-grid>
                    <mds-row>
                        <mds-col :cols="6">
                            <div class="showUniTemp">
                                <mds-list-group
                                    v-if="checkUniverseType == 'Static'"
                                >
                                    <mds-list-group-item
                                        v-for="(
                                            item, index
                                        ) in universeStaticShowData"
                                        :key="index"
                                    >
                                        {{ item.entityname }}
                                    </mds-list-group-item>
                                </mds-list-group>
                                <mds-list-group
                                    v-if="checkUniverseType == 'Dynamic'"
                                >
                                    <mds-list-group-item
                                        v-for="(
                                            item, index
                                        ) in universeDynamicShowData"
                                        :key="index"
                                    >
                                        <span style="margin-right: 50px">{{
                                            item.datapointname
                                        }}</span>
                                        <span
                                            v-for="(value, ix) in item.values"
                                            :key="ix"
                                            >{{ value }}
                                            <span
                                                v-if="
                                                    ix + 1 < item.values.length
                                                "
                                                >,
                                            </span>
                                        </span>
                                    </mds-list-group-item>
                                </mds-list-group>
                            </div>
                        </mds-col>
                        <mds-col :cols="6">
                            <div class="showUniTemp">
                                <div
                                    class="template-list"
                                    v-for="(
                                        item, indexList
                                    ) in templateShowdata"
                                    :key="indexList"
                                >
                                    <span>{{ item.name }}</span>
                                </div>
                            </div>
                        </mds-col>
                    </mds-row>
                </mds-layout-grid>
            </div>
            </div>
            <common-footer
                buttonName="Save"
                @cancelButton="cancelButton"
                @saveButton="saveButton"
                :isDisabled="!saveButtonDisable"
            >
            </common-footer>
        </div>
        <loader-component v-if="showLoader"></loader-component>
    </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsForm from "@mds/form";
import ButtonComponent from "../../../ui_component/ButtonComponent.vue";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import MdsSelect from "@mds/select";
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";
import MdsInput from "@mds/input";
import CommonFooter from "../components/commonFooter.vue";

export default {
    name: "create-data-feed",
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsForm,
        ButtonComponent,
        MdsSelect,
        LoaderComponent,
        MdsListGroup,
        MdsListGroupItem,
        MdsInput,
        CommonFooter,
    },

    data() {
        return {
            isVelotemplatetype: 1,
            showLoader: false,
            template: 0,
            universe: 0,
            nameFeed: "",
            selectedUniverseId: 0,
            selectedTemplateId: 0,
            toggleCancelButton: false,
        };
    },

    props: {
        dropDownUniversesList: {
            type: Array,
            default: () => [],
        },
        dropDownTemplateList: {
            type: Array,
            default: () => [],
        },
        universeStaticShowData: {
            type: Array,
            default: () => [],
        },
        universeDynamicShowData: {
            type: Array,
            default: () => [],
        },
        templateShowdata: {
            type: Array,
            default: () => [],
        },
        checkUniverseType: {
            type: String,
        },
        dataFeedHeaderTitle: {
            type: String,
        },
        updateDataFeedData: {
            type: Object,
        },
        dataFeedInputField: {
            type: Boolean,
        },
    },

    computed: {
        saveButtonDisable() {
            return this.template && this.universe && this.nameFeed;
        },
    },
    created() {
        const { listid, feedname, templateid, templatetype } = this.updateDataFeedData;
        this.selectedUniverseId = listid;
        this.selectedTemplateId = templateid;
        this.universe = listid;
        this.template = templateid;
        this.nameFeed = feedname;
        this.isVelotemplatetype = templatetype;
    },

    methods: {
        changeUniverse() {
            this.$emit("editUniverseData", this.universe);
        },

        changeTemplate() {
            this.$emit("editTemplateData", this.template);
        },

        universeButton() {
            this.$router.push({ name: "Reporting Create Universe" });
        },

        templateButton() {
            this.$router.push({ name: "Reporting Create Template" });
        },
        cancelButton() {
            this.$router.push('/data-dissemination/data-feeds');
        },

        //save Data Feed
        saveButton() {
            // const selectedUniverse = JSON.parse(this.universe);
            // const selectedTemplate = JSON.parse(this.template);
            let obj = {};
            obj.universeId = this.universe;
            obj.templateId = this.template;
            obj.feedName = this.nameFeed;
            if (this.updateDataFeedData != undefined) {
                obj.feedId = this.updateDataFeedData.feedid;
            }
            this.$emit("saveDataFeed", obj);
        },

        getRouteDetails(url) {
            const routeDetails = this.$router.resolve({
                path: url,
            });
            return routeDetails.resolved.name;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

.create-data-feed {
    padding: 8px 16px 0 16px;
}
.create-data-feed-heading {
    margin-top: $mds-space-2-x;
    margin-bottom: $mds-space-2-x;
    left: 16px;
    top: 50px;
    font-style: normal;
    font-weight: 250;
    font-size: 32px;
    line-height: 36px;
    color: #1e1e1e;
}
.template-list {
    display: flex;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 5px;
    padding-top: 8px;
}
.reportPreviewText {
    margin-top: 4px;
    position: static;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    font-size: 23px;
    line-height: 27px;
    color: #1e1e1e;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.footerSection {
    margin-top: 10px;
    margin-bottom: 10px;
}
.bottom-section-button {
    margin-top: $mds-space-2-and-a-half-x;
}
.bottom-section-run-button {
    margin-left: $mds-space-2-x;
}
.showUniTemp {
    margin-top: 15px;
    height: 325px;
    overflow: auto;
}
::v-deep .mds-list-group__link___VueMDS3Demo {
    padding: 0;
}
.template-list {
    display: flex;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 5px;
}
.upperSection{
    min-height: 83vh;
}
</style>
